/*
 *   File : user-detail.js
 *   Author URI : https://evoqins.com
 *   Description : User detail page
 *   Integrations : null
 *   Version : v1.1
 */

/* import packages */
import React, { Fragment, useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Modal } from "bootstrap";

/* import components */
import { NavigationHeader } from "../../../Components/Header";
import { UserDetailSummary } from "../../../Components/Cards";
import { CustomTab } from "../../../Components/Tab";
import { Icon } from "../../../Components/Icon";
import { DeleteUser } from "../../../Components/Modal";
/* import container */
import { CustomerProfile, MandateList, OrderList, Portfolio, RiskAssessment, SipList, CustomerDetail, SipHistory, Report, JointHolders, MinorDetail } from "./helper";
import { CustomLoader } from "../../../Components/Other";
import APIService from "../../../Services/api-service";
import { BreadCrumb } from "../../../Components/Breadcrumb";
import { GradientButton } from "../../../Components/Cta";

/* import styles */

const PAGE_NAVIGATION = [
    "Dashboard",
    "Users",
    "User details"
]

const TAB_DATA = [
    "Profile & KYC",
    "Portfolio",
    "Orders",
    "SIPs",
    "Mandates",
    "Risk-Assessment",
    "SIP History",
    "Reports"
]

const AdminUserDetail = React.memo(() => {

    const ADMIN_PROFILE_DATA = useSelector(state => state.MT_ADMIN_STORE.ADMIN_PROFILE);
    const location = useLocation();
    const navigation = useNavigate();
    
    const [syncing, setSyncing] = useState(false);
    const [tabIndex, setTabIndex] = useState(1);
    const [userData, setUserData] = useState({});
    const [summaryData, setSummaryData] = useState({});
    const [loader, setLoader] = useState(true);
    const [tabData, setTabData] = useState([]);
    const [showDeletedRed, setShowDeletedRed] = useState(false);
    const [openRemoveModal, setOpenRemoveModal] = useState(false);
    const [permissionType, setPermissionType] = useState('');
    const [initialRender, setInitialRender] = useState(true);
    
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        if (initialRender === true) {
            setInitialRender(false);
        }else{
            _getUserSummary();
        }
    }, [initialRender]);


    useEffect(() => {
        const page_type = ADMIN_PROFILE_DATA.permission_details.filter((permission) => location.pathname.includes(permission.short_name.toLowerCase().replace(/_/g, '-')))
        if (page_type.length > 0) {
            setPermissionType(page_type[0].action);
            setInitialRender(false);
        }
    }, [ADMIN_PROFILE_DATA, location]);



    useEffect(() => {
        if (openRemoveModal === true) {
            const modal = new Modal(document.getElementById("delete-user"), {});
            modal.show();
        }
    }, [openRemoveModal]);

    useEffect(() => {
        return () => {
            setTabData([]);
        };
    }, []);

    useEffect(() => {
        if (Object.keys(userData).length !== 0) {
            if (tabData.length === 0) {
                let tab = [...TAB_DATA];
                console.log('userData', userData.profile);
                if (userData.profile.account_type === 'Joint account') {
                    tab.push('Joint holders');
                }
                if (userData.profile.account_type === 'Anyone or Survivor') {
                    tab.push('Other holders');
                }
                if (userData.profile.account_type === 'Either or Survivor') {
                    tab.push('Other holders');
                }
                if (userData.profile.minor_flag === true) {
                    tab.push('Guardian details');
                }
                if (userData.profile.is_kyc_done === true) {
                    tab.push('Update NFT');
                }
                console.log('tab', tab);
                setTabData(tab);
            }
        }
    }, [userData]);

    useEffect(() => {
        if (location.state !== null && initialRender === false) {
            _getUserData();
        }
    }, [location, initialRender]);

    const _handleTabIndex = (tab_index) => {
        setTabIndex(tab_index);
    }

    function _closeRemoveModal() {
        setOpenRemoveModal(false);
    }

    function _navigateToKYC() {
        navigation('/create-account', {
            state: {
                user_id: location.state.user_id
            }
        })
    }

    // API - Get user data
    function _getUserData() {
        let url = 'user/profile/get';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setUserData(response.data);
            }
            setLoader(false);
        })
    }

    // API - Get user summary
    function _getUserSummary() {
        let url = 'user/profile/summary';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                setSummaryData(response.data);
                setInitialRender(false);
            }
        })
    }

    // API - sync folios
    function _syncFolio() {
        setSyncing(true);
        let url = 'user/folio-sync';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success('Data synced successfully', {
                    type: 'success'
                });
                _getUserData();
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
            setSyncing(false);
        })
    }

    function _handleDelete() {
        let url = 'user/delete';
        let payload = JSON.stringify({
            customer_id: location.state.user_id
        });
        APIService(true, url, payload).then((response) => {
            if (response.status_code === 200) {
                toast.success('Successfully OTP send', {
                    type: 'success'
                });
                setOpenRemoveModal(true);
            } else {
                toast.error(response.message, {
                    type: 'error'
                })
            }
        })

    }

    return (
        <Fragment>
            <NavigationHeader
                title="User details" />
            {
                loader === true ?
                    <CustomLoader />
                    :
                    <div className="ps-4 pe-lg-5 pe-md-2 pe-2 padding-42px-top">
                        <div className="d-flex justify-content-between">
                            <BreadCrumb data={PAGE_NAVIGATION} />
                            {
                                permissionType === 'write' && userData.profile.is_deleted !== true &&
                                <p className={`${showDeletedRed === true ? 'color-red' : 'color-primary-color'} line-height-16px e-poppins-regular e-font-14 cursor-pointer my-0  ms-3 position-relative h-fit-content `}
                                    onMouseEnter={() => {
                                        setShowDeletedRed(true);
                                    }}
                                    onMouseLeave={() => {
                                        setShowDeletedRed(false);
                                    }}
                                    onClick={() => {
                                        _handleDelete()
                                    }}>
                                    Delete user

                                    {/* <span className="e-tool-tip-container position-relative "
                                            data-tooltip-content="Delete selected"
                                            placement="bottom"
                                        > */}
                                    <Icon icon={showDeletedRed === true ? "delete-red" : 'delete'}
                                        size={24}
                                        className="ms-1" />
                                    {/* </span> */}
                                </p>
                            }

                        </div>
                        <div className="row">
                            <div className="col-12">
                                <UserDetailSummary
                                    basicInfo={userData.profile}
                                    summaryInfo={summaryData}
                                    updateDetail={_getUserData} />
                            </div>
                        </div>
                        <div className="row gx-0 padding-28px-top">
                            <div className="col-8 scrollbar-container overflow-auto white-space-nowrap">
                                <CustomTab data={tabData}
                                    index={tabIndex}
                                    onSelectTab={_handleTabIndex} />
                            </div>
                            {
                                ADMIN_PROFILE_DATA.admin_type_id === 2 && tabIndex === 1 &&
                                <div className="col-4 text-md-end ">
                                    {
                                        permissionType === 'write' && userData.profile.is_deleted !== true && userData.profile.kyc_step <= 10 &&
                                        <GradientButton label={userData.profile.kyc_step <= 0 ? "Create KYC" : "Complete KYC"}
                                            onPress={_navigateToKYC}
                                            className="me-2" />
                                    }

                                    <GradientButton label="Sync data"
                                        onPress={_syncFolio}
                                        loading={syncing} />
                                </div>
                            }
                        </div>
                        {
                            tabIndex === 1 ?
                                <CustomerProfile customer_info={userData} />
                                :
                                tabIndex === 2 ?
                                    <Portfolio />
                                    :
                                    tabIndex === 3 ?
                                        <OrderList />
                                        :
                                        tabIndex === 4 ?
                                            <SipList /> :
                                            tabIndex === 5 ?
                                                <MandateList userBank={userData.profile.banks} />
                                                :
                                                tabIndex === 6 ?
                                                    <RiskAssessment />
                                                    :
                                                    tabIndex === 7 ?
                                                        <SipHistory userId={location.state.user_id} />
                                                        :
                                                        tabIndex === 8 ?
                                                            <Report userId={location.state.user_id} />
                                                            :
                                                            tabIndex === 9 ?
                                                                <>
                                                                    {
                                                                        userData.profile.minor_flag === true ?
                                                                            <MinorDetail data={userData.profile} />
                                                                            :
                                                                            (userData.profile.account_type === 'Joint account' || userData.profile.account_type === 'Either or Survivor' || userData.profile.account_type === 'Anyone or Survivor') ?
                                                                                <JointHolders data={userData.profile} />
                                                                                :
                                                                                <CustomerDetail data={userData}
                                                                                    userId={location.state.user_id} />
                                                                    }
                                                                </> :
                                                                tabIndex === 10 ?
                                                                    <>
                                                                        {
                                                                            userData.profile.minor_flag === true && (userData.profile.account_type === 'Joint account' || userData.profile.account_type === 'Either or Survivor' || userData.profile.account_type === 'Anyone or Survivor') ?
                                                                                <JointHolders data={userData.profile} />
                                                                                :
                                                                                <CustomerDetail data={userData}
                                                                                    userId={location.state.user_id} />
                                                                        }
                                                                    </>

                                                                    :
                                                                    <CustomerDetail data={userData}
                                                                        userId={location.state.user_id} />
                        }

                    </div>
            }
            {
                openRemoveModal === true &&
                <DeleteUser userId={location.state.user_id}
                    close={_closeRemoveModal} />
            }
        </Fragment>
    )
})

export default AdminUserDetail;